import React, { FC, useMemo, useCallback, useState, useEffect, useRef } from "react";
import { Card, CardWrapper, useRequestInit, PlatformFoutenSamenvatting, FormFirstFocus, Icon } from "adviesbox-shared";
import { Formik, FormikProps } from "formik";
import "./scenario-drawer.scss";
import { InkomensanalyseTabelWerkloosheid, InkomensanalyseProps } from "./werkloosheid-drawer";
import { InkomensanalyseTabelArbeidsongeschiktheid, ArbeidsongeschiktheidProps } from "./arbeidsongeschiktheid-drawer";
import InkomensanalyseTabelOverlijden, { OverlijdenProps } from "./overlijden-drawer";
import { InkomensanalyseTabelPensioen, PensioenDataProps } from "./pensioen-drawer";
import { NewDashboardType } from "../new-dashboard-context";
import { useFetchData } from "../../../shared/hooks/usefetchdata";
import { ControleNorm } from "../../components/controle-norm/controle-norm";
import { ScenarioVan } from "../../components/scenario-van/scenario-van";
import { NamePrefix } from "../../infra/dashboard-types";
import {
  DashboardOutput,
  ControleGewenstInkomenOptions,
  ControleMaandlastOptions
} from "../../../.generated/forms/formstypes";
import { mapDashboardDlToUi } from "../../infra/map-dashboard-dl-2-ui";
import { SaveButton } from "../../../shared/components/save-button/save-button";
import { MotiveringCompleetOutput } from "../../../.generated/rapportage/rapportagetypes";
import { saveDataRequestInit as createSaveData } from "../../../shared/utils/save-data";
import { mapDashboardUiToDl } from "../../infra/map-dashboard-ui-2-dl";
import { ScenarioDoorrekening } from "../../scenario-arbeidsongeschiktheid-modal/scenario-doorrekening/scenario-doorrekening";
import {
  mapMotiveringAOToInkomensanalyseProps,
  mapMotiveringOverlijdenToInkomensanalyseProps,
  mapMotiveringWWToInkomensanalyseProps,
  mapMotiveringPensioenToInkomensanalyseProps
} from "./infra/scenario-mappers";
import { LabelValuePairs, AanvragerKeuze } from "../../../shared/types";
import { getNaam } from "../../../shared/utils/helpers";
import { getKlantIdRequired } from "./infra/scenario-utils";
import { CardLoadingSpinner } from "./card-loading-spinner";
import { dashboardSchema } from "../../infra/dashboard-schema";
import { useRefreshTrigger } from "../infra/use-refresh-trigger";
import { cleanCache } from "../infra/scenario-cache";
import { IconButton } from "@material-ui/core";
import classNames from "classnames";
import { useCookieBoolean } from "../../../useCookieSettings";

interface ScenarioDrawerProps {
  soort:
    | NewDashboardType.Arbeidsongeschiktheid
    | NewDashboardType.Werkloosheid
    | NewDashboardType.Pensioen
    | NewDashboardType.Overlijden;
}

export type ScenarioBaseProps = {
  weergevenOptions: LabelValuePairs;
  loading: boolean;
  onKlantIdChange: (newKlantId: string) => void;
  selectedKlantId: string;
  maximaleHypotheekWeergeven: boolean;
  controleWerkelijkeLastenWeergeven: boolean;
  benodigdKapitaalWeergeven: boolean;
  brutoInkomensWensenWeergeven: boolean;
  brutoInkomensWensPercentage: number | null;
  controleMaandlast: boolean;
  controleMaandlastKeuze: ControleMaandlastOptions | null;
};

export const ScenarioDrawer: FC<ScenarioDrawerProps> = ({ soort }: ScenarioDrawerProps) => {
  const [rerenderKey, setRerenderKey] = useState<number>(0);
  const [weergevenOptions, setWeergevenOptions] = useState<LabelValuePairs>([]);
  const [isCollapsed, setCollapsed] = useCookieBoolean("scenario-instellingen-weergeven", true);
  const { refreshTrigger } = useRefreshTrigger();
  const lastRefreshTrigger = useRef(refreshTrigger);
  
  const { settings, params, requestInit, user } = useRequestInit<{
    vestiging: string;
    adviesdossier: string;
    voorstel: string;
  }>();

  const [selectedKlantId, setSelectedKlantId] = useState<string>("");
  const [formikKey, setFormikKey] = useState(0);

  const getScenarioUrl = useMemo(() => {
    const baseUrl = `${settings.rapportageOrigin}`;
    return params.voorstel
      ? `${baseUrl}/Voorstellen/${params.voorstel}/Motivering/${soort.toLowerCase()}/Compleet`
      : `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Motivering/${soort.toLowerCase()}/Compleet`;
  }, [params.adviesdossier, params.voorstel, settings.rapportageOrigin, soort]);

  const getDashboardUrl = useMemo(() => {
    const baseUrl = `${settings.klantdossiersFormsOrigin}`;
    return `${baseUrl}/Adviesdossiers/${params.adviesdossier}/Dashboard/${soort.toLowerCase()}`;
  }, [settings.klantdossiersFormsOrigin, params.adviesdossier, soort]);

  const fullDashboardUrl = useMemo(() => {
    return `${getDashboardUrl}?berekenTekort=false`;
  }, [getDashboardUrl]);

  const saveData = useCallback(
    createSaveData(
      getDashboardUrl,
      requestInit,
      mapDashboardUiToDl,
      () => null,
      () => null
    ),
    [getDashboardUrl, requestInit]
  );

  const getDashboardRequestInit = useCallback(
    () => ({
      ...requestInit,
      url: getDashboardUrl,
      method: "GET"
    }),
    [requestInit, getDashboardUrl]
  );

  const { data: dashboardData, loading: dashboardLoading, refetch: refetchDashboardData } = useFetchData<
    DashboardOutput
  >(fullDashboardUrl, getDashboardRequestInit);

  const dashData = dashboardData?.dashboards
    ? dashboardData.dashboards[Object.keys(dashboardData.dashboards)[0]]
    : null;
  const dashboardSettings = dashData ? mapDashboardDlToUi(dashData) : null;

  const getScenarioRequestInit = useCallback(
    (klantid: string): RequestInit => {
      const { headers: baseHeaders, ...baseInit } = requestInit;
      return {
        ...baseInit,
        method: "POST",
        headers: {
          ...baseHeaders,
          klantId: klantid
        }
      };
    },
    [requestInit]
  );

  const aanvragerKlantid = dashboardSettings?.aanvrager1.klantId;
  const selectedKlantIdOrDefault = useMemo(() => {
    if (selectedKlantId) return selectedKlantId;
    if (aanvragerKlantid) return aanvragerKlantid;
    return "";
  }, [selectedKlantId, aanvragerKlantid]);

  const getCurrentRequestInit = useCallback(() => {
    return getScenarioRequestInit(selectedKlantIdOrDefault);
  }, [getScenarioRequestInit, selectedKlantIdOrDefault]);

  const {
    data: scenarioData,
    loading: scenarioLoading,
    refetch: refetchScenarioData,
    setData: setScenarioData
  } = useFetchData<MotiveringCompleetOutput>(
    getScenarioUrl,
    getCurrentRequestInit,
    !!(dashboardData && selectedKlantIdOrDefault && getCurrentRequestInit()),
    false,
    true
  );

  const updateWeergevenOptions = useCallback(
    (data: DashboardOutput | null | undefined) => {
      if (data && data.dashboards) {
        const dashboard = Object.values(data.dashboards)[0];
        if (dashboard) {
          const newOptions: LabelValuePairs = [];
          if (dashboard.aanvrager1) {
            newOptions.push({
              label: getNaam(dashboard.aanvrager1, "Aanvrager 1"),
              value: dashboard.aanvrager1.klantId || ""
            });
          }
          if (dashboard.aanvrager2) {
            newOptions.push({
              label: getNaam(dashboard.aanvrager2, "Aanvrager 2"),
              value: dashboard.aanvrager2.klantId || ""
            });
          }
          // todo: #pbinummer nbt
          // if (
          //   (soort === "Arbeidsongeschiktheid" || soort === "Werkloosheid") &&
          //   dashboard.aanvrager1 &&
          //   dashboard.aanvrager2
          // ) {
          //   newOptions.push({
          //     label: "Beiden",
          //     value: ""
          //   });
          // }
          setWeergevenOptions(newOptions);
        }
      }
    },
    [setWeergevenOptions]
  );

  useEffect(() => {
    if (dashboardData) {
      updateWeergevenOptions(dashboardData);
    }
  }, [dashboardData, updateWeergevenOptions]);

  // selectedKlantId bijwerken indien beschikbaar
  useEffect(() => {
    const aanvrager1KlantId = aanvragerKlantid;
    if (!selectedKlantId && aanvrager1KlantId) {
      setSelectedKlantId(aanvrager1KlantId);
    }
  }, [aanvragerKlantid, selectedKlantId]);

  // zorg dat we de data ophalen als de klantId bekend is
  useEffect(() => {
    if (!scenarioLoading && selectedKlantIdOrDefault && refreshTrigger !== lastRefreshTrigger.current) {
      const requestInit = getScenarioRequestInit(selectedKlantIdOrDefault);
      lastRefreshTrigger.current = refreshTrigger;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetchScenarioData(requestInit);
    }
  }, [refreshTrigger, selectedKlantIdOrDefault, getScenarioRequestInit, scenarioLoading, refetchScenarioData]);
  
  const handleKlantIdChange = useCallback((newKlantId: string) => {
    setSelectedKlantId(newKlantId);
  }, []); 

  const handleSave = useCallback(
    async (formikValues: any): Promise<null> => {
      try {
        setScenarioData(null, null);
        await saveData(formikValues);
        await cleanCache(user, params, settings);
        await refetchDashboardData();
        updateWeergevenOptions(dashboardData);
        await refetchScenarioData();
        setRerenderKey(prevKey => prevKey + 1);
        setFormikKey(prevKey => prevKey + 1);
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error("Error saving data:", error);
      }
      return null;
    },
    [
      saveData,
      setScenarioData,
      refetchDashboardData,
      updateWeergevenOptions,
      dashboardData,
      refetchScenarioData,
      params,
      settings,
      user
    ]
  );

  const aanvragerKeuze: AanvragerKeuze = selectedKlantId
    ? weergevenOptions.findIndex(option => option.value === selectedKlantId) === 0
      ? AanvragerKeuze.Aanvrager1
      : AanvragerKeuze.Aanvrager2
    : AanvragerKeuze.Beiden;

  const scenarioWerkloosheid = scenarioData
    ? mapMotiveringWWToInkomensanalyseProps(scenarioData, aanvragerKeuze)
    : null;
  const scenarioArbeidsongeschiktheid = scenarioData
    ? mapMotiveringAOToInkomensanalyseProps(scenarioData, aanvragerKeuze)
    : null;
  const scenarioOverlijden = scenarioData ? mapMotiveringOverlijdenToInkomensanalyseProps(scenarioData) : null;
  const scenarioPensioen = scenarioData ? mapMotiveringPensioenToInkomensanalyseProps(scenarioData) : null;

  return (
    <div className="snel-inzicht">
      <CardWrapper maxRowCount={2} flexType="flex-row">
        <div
          className={classNames("snel-inzicht__column col-12", { "col-md-1": isCollapsed, "col-md-4": !isCollapsed })}
        >
          <Formik
            key={formikKey}
            initialValues={dashboardSettings || dashboardSchema.default()}
            enableReinitialize
            onSubmit={() => Promise.resolve(true)}
          >
            {(formik: FormikProps<any>) => {
              return (
                <FormFirstFocus>
                  <PlatformFoutenSamenvatting />
                  <Card
                    title={!isCollapsed ? "Instellingen" : ""}
                    titlePrepend={
                      <IconButton
                        title="Instellingen"
                        className="snel-inzicht__button"
                        onClick={() => setCollapsed(!isCollapsed)}
                      >
                        <Icon name="hamburger" iconSize="l" />
                      </IconButton>
                    }
                    className="scenario__card"
                  >
                    {!isCollapsed && (
                      <>
                        {(formik.isSubmitting || dashboardLoading) && <CardLoadingSpinner />}
                        {formik.values && <ControleNorm namePrefix={soort.toLowerCase() as NamePrefix} />}
                        {formik.values?.aanvrager2 && (
                          <>
                            <h3 className="pl-3 pt-3">Scenario van (opnemen in rapportage)</h3>
                            <ScenarioVan
                              namePrefix={soort.toLowerCase() as NamePrefix}
                              beideTonen={!!dashData?.aanvrager2 && !getKlantIdRequired(soort, selectedKlantId)}
                            />
                          </>
                        )}
                        {soort === NewDashboardType.Arbeidsongeschiktheid && (
                          <ScenarioDoorrekening namePrefix={soort.toLowerCase() as NamePrefix} />
                        )}
                        <br />
                        <div className="text-center">
                          <SaveButton
                            context={{
                              ...formik,
                              saveData: handleSave
                            }}
                            callBack={async () => {
                              await refetchScenarioData();
                              setRerenderKey(prevKey => prevKey + 1);
                              setFormikKey(prevKey => prevKey + 1);
                            }}
                            name="Opslaan en herberekenen"
                            label="Opslaan en herberekenen"
                            propagateDirty={false}
                          />
                        </div>
                      </>
                    )}
                  </Card>
                </FormFirstFocus>
              );
            }}
          </Formik>
        </div>
        {!!dashboardSettings && (
          <Card
            title={`Scenario: ${soort}`}
            className={classNames("snel-inzicht__column col-12", {
              "col-md-11": isCollapsed,
              "col-md-8": !isCollapsed
            })}
          >
            {soort === NewDashboardType.Werkloosheid && (
              <InkomensanalyseTabelWerkloosheid
                {...(scenarioWerkloosheid || ({} as InkomensanalyseProps))}
                key={rerenderKey}
                loading={scenarioLoading}
                nbiWeergeven={
                  (dashboardSettings?.werkloosheid.controleNorm.controleGewenstInkomen === "Nbi" &&
                    dashboardSettings.werkloosheid.controleNorm.gewijzigdeJaren) ||
                  false
                }
                weergevenOptions={weergevenOptions}
                onKlantIdChange={handleKlantIdChange}
                selectedKlantId={selectedKlantId || dashboardSettings?.aanvrager1.klantId || ""}
                maximaleHypotheekWeergeven={
                  dashboardSettings?.werkloosheidUitgangspunten?.controleHypotheeknorm?.controleerMaximaleHypotheek ||
                  false
                }
                benodigdKapitaalWeergeven={
                  dashboardSettings?.werkloosheidUitgangspunten?.controleGewenstInkomen?.benodigdKapitaalTonen || false
                }
                controleWerkelijkeLastenWeergeven={
                  (dashboardSettings?.werkloosheid.controleNorm.controleMaandlast || false) &&
                  dashboardSettings?.werkloosheidUitgangspunten?.controleHypotheeknorm?.controleMaandlast ===
                    ControleMaandlastOptions.WerkelijkeLast
                }
                brutoInkomensWensenWeergeven={
                  dashboardSettings?.werkloosheidUitgangspunten?.controleGewenstInkomen?.controleGewenstInkomen ===
                  ControleGewenstInkomenOptions.BrutoInkomenswens
                }
                brutoInkomensWensPercentage={
                  dashboardSettings?.werkloosheidUitgangspunten?.controleGewenstInkomen?.brutoInkomenswensPercentage ||
                  0
                }
                controleMaandlast={dashboardSettings?.werkloosheid.controleNorm.controleMaandlast || false}
                controleMaandlastKeuze={dashboardSettings?.werkloosheid.controleNorm.controleMaandLastKeuze || null}
              />
            )}
            {soort === NewDashboardType.Arbeidsongeschiktheid && (
              <InkomensanalyseTabelArbeidsongeschiktheid
                {...(scenarioArbeidsongeschiktheid || ({} as ArbeidsongeschiktheidProps))}
                key={rerenderKey}
                loading={scenarioLoading}
                nbiWeergeven={
                  (dashboardSettings?.arbeidsongeschiktheid.controleNorm.controleGewenstInkomen === "Nbi" &&
                    dashboardSettings.arbeidsongeschiktheid.controleNorm.gewijzigdeJaren) ||
                  false
                }
                weergevenOptions={weergevenOptions}
                onKlantIdChange={handleKlantIdChange}
                selectedKlantId={selectedKlantId || dashboardSettings?.aanvrager1.klantId || ""}
                maximaleHypotheekWeergeven={
                  dashboardSettings?.arbeidsongeschiktheidUitgangspunten?.controleHypotheeknorm
                    ?.controleerMaximaleHypotheek || false
                }
                benodigdKapitaalWeergeven={
                  dashboardSettings?.arbeidsongeschiktheidUitgangspunten?.controleGewenstInkomen
                    ?.benodigdKapitaalTonen || false
                }
                controleWerkelijkeLastenWeergeven={
                  (dashboardSettings?.arbeidsongeschiktheid.controleNorm.controleMaandlast || false) &&
                  dashboardSettings?.arbeidsongeschiktheidUitgangspunten?.controleHypotheeknorm?.controleMaandlast ===
                    ControleMaandlastOptions.WerkelijkeLast
                }
                brutoInkomensWensenWeergeven={
                  dashboardSettings?.arbeidsongeschiktheidUitgangspunten?.controleGewenstInkomen
                    ?.controleGewenstInkomen === ControleGewenstInkomenOptions.BrutoInkomenswens
                }
                brutoInkomensWensPercentage={
                  dashboardSettings?.arbeidsongeschiktheidUitgangspunten?.controleGewenstInkomen
                    ?.brutoInkomenswensPercentage || 0
                }
                controleMaandlast={dashboardSettings?.arbeidsongeschiktheid.controleNorm.controleMaandlast || false}
                controleMaandlastKeuze={
                  dashboardSettings?.arbeidsongeschiktheid.controleNorm.controleMaandLastKeuze || null
                }
              />
            )}
            {soort === NewDashboardType.Overlijden && (
              <InkomensanalyseTabelOverlijden
                {...(scenarioOverlijden || ({} as OverlijdenProps))}
                key={rerenderKey}
                loading={scenarioLoading}
                nbiWeergeven={
                  (dashboardSettings?.overlijden.controleNorm.controleGewenstInkomen === "Nbi" &&
                    dashboardSettings.overlijden.controleNorm.gewijzigdeJaren) ||
                  false
                }
                weergevenOptions={weergevenOptions}
                onKlantIdChange={handleKlantIdChange}
                selectedKlantId={selectedKlantId || dashboardSettings?.aanvrager1.klantId || ""}
                maximaleHypotheekWeergeven={
                  dashboardSettings?.overlijdenUitgangspunten?.controleHypotheeknorm?.controleerMaximaleHypotheek ||
                  false
                }
                controleInkomenWeergeven={true}
                benodigdKapitaalWeergeven={
                  dashboardSettings?.overlijdenUitgangspunten?.controleGewenstInkomen?.benodigdKapitaalTonen || false
                }
                controleWerkelijkeLastenWeergeven={
                  (dashboardSettings?.overlijden.controleNorm.controleMaandlast || false) &&
                  dashboardSettings?.overlijdenUitgangspunten?.controleHypotheeknorm?.controleMaandlast ===
                    ControleMaandlastOptions.WerkelijkeLast
                }
                brutoInkomensWensenWeergeven={
                  dashboardSettings?.overlijdenUitgangspunten?.controleGewenstInkomen?.controleGewenstInkomen ===
                  ControleGewenstInkomenOptions.BrutoInkomenswens
                }
                brutoInkomensWensPercentage={
                  dashboardSettings?.overlijdenUitgangspunten?.controleGewenstInkomen?.brutoInkomenswensPercentage || 0
                }
                controleMaandlast={dashboardSettings?.overlijden.controleNorm.controleMaandlast || false}
                controleMaandlastKeuze={dashboardSettings?.overlijden.controleNorm.controleMaandLastKeuze || null}
              />
            )}
            {soort === NewDashboardType.Pensioen && (
              <InkomensanalyseTabelPensioen
                {...(scenarioPensioen || ({} as PensioenDataProps))}
                key={rerenderKey}
                loading={scenarioLoading}
                nbiWeergeven={
                  (dashboardSettings?.pensioen.controleNorm.controleGewenstInkomen === "Nbi" &&
                    dashboardSettings.pensioen.controleNorm.gewijzigdeJaren) ||
                  false
                }
                weergevenOptions={weergevenOptions}
                onKlantIdChange={handleKlantIdChange}
                selectedKlantId={selectedKlantId || dashboardSettings?.aanvrager1.klantId || ""}
                maximaleHypotheekWeergeven={
                  dashboardSettings?.pensioenUitgangspunten?.controleHypotheeknorm?.controleerMaximaleHypotheek || false
                }
                benodigdKapitaalWeergeven={
                  dashboardSettings?.pensioenUitgangspunten?.controleGewenstInkomen?.benodigdKapitaalTonen || false
                }
                controleWerkelijkeLastenWeergeven={
                  (dashboardSettings?.pensioen.controleNorm.controleMaandlast || false) &&
                  dashboardSettings?.pensioenUitgangspunten?.controleHypotheeknorm?.controleMaandlast ===
                    ControleMaandlastOptions.WerkelijkeLast
                }
                brutoInkomensWensenWeergeven={
                  dashboardSettings?.pensioenUitgangspunten?.controleGewenstInkomen?.controleGewenstInkomen ===
                  ControleGewenstInkomenOptions.BrutoInkomenswens
                }
                brutoInkomensWensPercentage={
                  dashboardSettings?.pensioenUitgangspunten?.controleGewenstInkomen?.brutoInkomenswensPercentage || 0
                }
                controleMaandlast={dashboardSettings?.pensioen.controleNorm.controleMaandlast || false}
                controleMaandlastKeuze={dashboardSettings?.pensioen.controleNorm.controleMaandLastKeuze || null}
              />
            )}
          </Card>
        )}
      </CardWrapper>
    </div>
  );
};
