import {
  Icon,
  LabeledCurrencyInput,
  LabeledJaarMaandInput,
  LabeledSelectInput,
  LabeledTextInput,
  ModalButton,
  Card,
  SubscribeNotifyButton,
  useRequestInit,
  useFeature
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useCallback } from "react";
import AanvraagNaarHypotheekModal from "../aanvraag-naar-hypotheek-modal/aanvraag-naar-hypotheek-modal";
import { aanvraagHypotheekType, AanvraagState } from "../infra/aanvraag-schema";
import { AanvraagButton } from "../aanvraag-button/aanvraag-button";
import { getAanvraagTextResources } from "../infra/aanvraag-resources";
import { NieuweAanvraagInput } from "../../.generated/foundation/foundationtypes";
import { mapNieuweAanvraagInput } from "../infra/map-aanvraag-ui-to-dl";
import classes from "../aanvraag.module.scss";

type AanvraagHypotheekCardProps = {
  vestiging: string;
  adviesdossier: string;
  title: string;
  isLoading: boolean;
};

export const AanvraagHypotheek = ({
  title,
  isLoading,
  vestiging,
  adviesdossier
}: AanvraagHypotheekCardProps): ReactElement => {
  const featureNotificatie2 = useFeature("FeatureNotificaties2");
  const formik = useFormikContext<AanvraagState>();
  const { values, setFieldValue } = formik;
  const { settings, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const hypotheek = values.hypotheek;

  const requestInitNieuweAanvraagHypotheek = useCallback(
    (nieuweversie: boolean): RequestInit => {
      const nieuweAanvraag: NieuweAanvraagInput = mapNieuweAanvraagInput(
        values,
        adviesdossier,
        nieuweversie,
        values.aanvraagOpBasisVan
      );
      return {
        headers: {
          authorization: `${user?.token_type} ${user?.access_token}`,
          "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
          "Content-Type": "application/json",
          vestigingId: vestiging
        },
        method: "POST",
        body: JSON.stringify(nieuweAanvraag) // TODO
      };
    },
    [values, user, settings, vestiging, adviesdossier]
  );

  if (hypotheek == null) {
    return <></>;
  }

  return (
    <Card title={title} className="col-6">
      <LabeledTextInput caption="Partij" name="hypotheek.partijnaam" readonly />
      <LabeledTextInput caption="Product" name="hypotheek.productnaam" readonly />
      <LabeledJaarMaandInput caption="Looptijd" name="hypotheek.looptijdMaanden" readonly />
      {hypotheek.leningdelen.map((_leningdeel, index) => {
        return (
          <div key={index} id={`leningdeel-${index}`}>
            <LabeledTextInput
              caption={`Leningdeel ${index + 1}`}
              name={`hypotheek.leningdelen[${index}].aflossingsvorm`}
              key={`leningdeel-${index}`}
              readonly
            />
            <LabeledCurrencyInput
              key={`hoofdsom-${index}`}
              caption="Hoofdsom"
              name={`hypotheek.leningdelen[${index}].hoofdsom`}
              decimalen={2}
              readonly
            />
          </div>
        );
      })}
      <LabeledTextInput
        readonly
        caption="Aanwezig in Aanvragen"
        name={`hypotheek.aanwezigInBemiddeling`}
        appendChildren={
          <span>
            {hypotheek.aanwezigInBemiddeling === true ? `Ja, ${hypotheek.voorstel}, Versie ${hypotheek.versie}` : "Nee"}
          </span>
        }
      />
      {/* (tijdelijk) vervangen door selectie van een enkel product
      <LabeledBevestigingInput caption="Meenemen naar Aanvragen" name={`hypotheek.meenemenNaarBemiddeling`} /> 
      */}
      {hypotheek.aanwezigInBemiddeling && (
        <LabeledSelectInput
          name="hypotheek.overschrijven"
          caption={"Nieuwe aanvraag of nieuwe versie"}
          datatest-id={`hypotheek-overschrijven-${hypotheek.productId}`}
          options={[
            {
              label: "Nieuwe aanvraag",
              value: "false"
            },
            {
              label: "Nieuwe versie",
              value: "true"
            }
          ]}
        />
      )}
      <LabeledTextInput
        caption="Aanvraag naar"
        name="hypotheek.aanvraagNaar"
        readonly
        appendChildren={
          <>
            <ModalButton parent="hypotheek" resetSize={true} content={<Icon name="specificatie" alt="Aanvraag naar" />}>
              <AanvraagNaarHypotheekModal
                aanvraag={values}
                onSave={
                  /* istanbul ignore next */ (data: aanvraagHypotheekType): void => setFieldValue(`hypotheek`, data)
                }
              />
            </ModalButton>
          </>
        }
      />

      {featureNotificatie2 && (
        <div className={classes.button_container}>
          <div className={classes.button_wrapper}>
            <SubscribeNotifyButton
              context={formik}
              icon={null}
              data-testid={"hypotheekProduct-0"}
              buttonText={
                !hypotheek.aanvraagVerzonden
                  ? getAanvraagTextResources("productaanvragen")
                  : getAanvraagTextResources("productOpnieuwAanvragen")
              }
              requestInit={requestInitNieuweAanvraagHypotheek(false)}
              url={`${settings.foundationOrigin}/HdnOnline/bus/NieuweAanvraag`}
            />
          </div>
        </div>
      )}

      {featureNotificatie2 && hypotheek.aanvraagVerzonden && (
        <div className={classes.button_container}>
          <div className={classes.button_wrapper}>
            <SubscribeNotifyButton
              context={formik}
              icon={null}
              data-testid={"update-hypotheekProduct-0"}
              buttonText={getAanvraagTextResources("nieuweVersieProductAanvragen")}
              requestInit={requestInitNieuweAanvraagHypotheek(true)}
              url={`${settings.foundationOrigin}/HdnOnline/bus/NieuweAanvraag`}
            />
          </div>
        </div>
      )}

      {!featureNotificatie2 && (
        <AanvraagButton
          formik={formik}
          testId={"hypotheekProduct-0"}
          buttonTekst={
            !hypotheek.aanvraagVerzonden
              ? getAanvraagTextResources("productaanvragen")
              : getAanvraagTextResources("productOpnieuwAanvragen")
          }
          nieuweVersieAanvraag={false}
          productId={values.aanvraagOpBasisVan}
          isLoading={isLoading}
        />
      )}

      {!featureNotificatie2 && hypotheek.aanvraagVerzonden && (
        <AanvraagButton
          formik={formik}
          testId={"update-hypotheekProduct-0"}
          buttonTekst={getAanvraagTextResources("nieuweVersieProductAanvragen")}
          nieuweVersieAanvraag={true}
          productId={values.aanvraagOpBasisVan}
          isLoading={isLoading}
        />
      )}
    </Card>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  AanvraagHypotheek.displayName = "AanvraagHypotheek";
