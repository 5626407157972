import { jaarMaandInMaanden, mapLocalDateToString } from "adviesbox-shared";
import {
  AflossingsVormType,
  RentevariantOptions,
  HypotheekvergelijkerContractrente,
  HypotheekvergelijkerOfferte,
  HypotheekvergelijkerOverig,
  HypotheekvergelijkerVerklaringInkomen,
  HypotheekvergelijkerLeningdeel,
  HypotheekvergelijkerFiscaleVoortzetting,
  HypotheekvergelijkerInput
} from "../../../.generated/foundation/foundationtypes";
import { RentevariantOptions as HypotheekRentevariantOptions } from "../../../.generated/forms/formstypes";
import {
  contractRenteSchema,
  hypotheekFiscaleRegelingSchema,
  hypotheekSchema,
  offerteSchema,
  overigeSchema,
  verklaringInkomenSchema
} from "../../infra/hypotheek-schema";
import { createMapToDl } from "../../../shared/utils/create-map-to-dl";
import { HypotheekVergelijkenModalType } from "../hypotheek-vergelijken-types";

export const mapHypotheekVergelijkerAflossingsVormUi2Dl = (vorm: AflossingsVormType | null): AflossingsVormType => {
  if (vorm && AflossingsVormType[vorm] && vorm !== AflossingsVormType.KredietNoPay) {
    return AflossingsVormType[vorm];
  }
  return AflossingsVormType.Annuïteit;
};

export const mapHypotheekVergelijkerRentevariantUi2Dl = (
  variant: HypotheekRentevariantOptions | null
): RentevariantOptions => {
  switch (variant) {
    case HypotheekRentevariantOptions.Variabel:
      return RentevariantOptions.Variabel;
    default:
      return RentevariantOptions.Rentevast;
  }
};

const mapContractRente = createMapToDl(contractRenteSchema).to<HypotheekvergelijkerContractrente>({
  dagrente: v => v.dagRente,
  dalrente: v => v.dalRente,
  offerterente: v => v.offerteRente
});

const mapOfferte = createMapToDl(offerteSchema).to<HypotheekvergelijkerOfferte>({
  inclusiefVerlengingsduur: v => v.inclusiefVerlengingsduur,
  minimaleGeldigheidsduurInMaanden: v => v.geldigheidsduurMinimaal,
  minimaleOffertegeldigheidsduur: v => v.minimaleOfferteGeldigheidsDuur
});

const mapOverige = createMapToDl(overigeSchema).to<HypotheekvergelijkerOverig>({
  annuleringskostenOfferte: v => v.annuleringsKostenAcceptabel,
  verhuisregeling: v => v.verhuisRegeling,
  verplichteOrv: v => v.verplichteOrv
});

const mapVerklaringInkomen = createMapToDl(verklaringInkomenSchema).to<HypotheekvergelijkerVerklaringInkomen>({
  accountantsverklaring: v => v.accountantsverklaring,
  ibStukken: v => v.ibStukken,
  perspectief: v => v.perspectief,
  uwv: v => v.uwv,
  werkgever: v => v.werkgever
});

const mapFiscaleVoortzetting = createMapToDl(hypotheekFiscaleRegelingSchema).to<
  HypotheekvergelijkerFiscaleVoortzetting
>({
  ingebrachteWaardeBedrag: v => v.ingebrachteWaardeBedrag
});

const mapLeningdelen = createMapToDl(hypotheekSchema).to<HypotheekvergelijkerLeningdeel>({
  renteaftrekken: v => null, //todo:
  aanvangsdatum: v => mapLocalDateToString(v.product.ingangsdatum),
  aflossingsvorm: v => mapHypotheekVergelijkerAflossingsVormUi2Dl(v.hypotheekVorm.aflossingsvorm),
  deelBox1Bedrag: v => v.fiscalegegevens.deelBox1Bedrag,
  doorlopend: v => v.product.doorlopend,
  fiscaleVoortzetting: v =>
    v.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening && v.fiscaleRegeling
      ? mapFiscaleVoortzetting(v.fiscaleRegeling)
      : null,
  leningdeelBedrag: v => v.leningdeelgegevens.leningdeelHoofdsom.bedrag,
  looptijdInMaanden: v => jaarMaandInMaanden(v.product.looptijd),
  rentevariant: v => mapHypotheekVergelijkerRentevariantUi2Dl(v.leningdeelgegevens.renteVariant),
  rentevastperiodeInJaren: v => v.leningdeelgegevens.rentevastPeriodeJaar || /* istanbul ignore next */ null,
  restschuldFinanciering: v => v.hypotheekVorm.isRestschuldLening,
  starterslening: v => v.hypotheekVorm.isStartersLening,
  hypotheekvorm: _ => null
});

export function mapHypotheekVergelijkerUiToDl(modal: {
  hypotheekvergelijkenModal: HypotheekVergelijkenModalType;
  adviesdossierId: string;
  voorstelId: string;
}): HypotheekvergelijkerInput {
  return {
    adviesdossierId: modal.adviesdossierId,
    voorstelId: modal.voorstelId,
    contractrente: modal.hypotheekvergelijkenModal.voorwaardeSelectie.contractRente
      ? mapContractRente(modal.hypotheekvergelijkenModal.voorwaardeSelectie.contractRente)
      : null,
    leningdelen: modal.hypotheekvergelijkenModal.hypotheekSamenstelling.map(c => mapLeningdelen(c)),
    offerte: modal.hypotheekvergelijkenModal.voorwaardeSelectie.offerte
      ? mapOfferte(modal.hypotheekvergelijkenModal.voorwaardeSelectie.offerte)
      : null,
    overig: modal.hypotheekvergelijkenModal.voorwaardeSelectie.overige
      ? mapOverige(modal.hypotheekvergelijkenModal.voorwaardeSelectie.overige)
      : null,
    verklaringInkomen: modal.hypotheekvergelijkenModal.voorwaardeSelectie.verklaringInkomen
      ? mapVerklaringInkomen(modal.hypotheekvergelijkenModal.voorwaardeSelectie.verklaringInkomen)
      : null
  };
}
