import { createSpanWithId, DataGrid, Icon, SortType } from "adviesbox-shared";
import { useFormikContext } from "formik";
import * as React from "react";
import { ReactElement, useState } from "react";
import { Column } from "react-table-6";
import { castUitslag2Icon } from "../../../hypotheek-oversluiten/data-grid/data-grid-helpers/data-grid-icons/data-grid-icons";
import { bedragFormat } from "../../../shared/utils/currency";
import { percentageFormat } from "../../../shared/utils/percentage-format";
import { HypotheekResultaatType, HypotheekVergelijkenModalType } from "../hypotheek-vergelijken-types";

export const getRentedalingStars = (rentedalingDirect: boolean, rentedalingNaRvp: boolean): string => {
  if (rentedalingNaRvp) return "*";
  if (rentedalingDirect) return "**";
  return "";
};
export const HypotheekVergelijkerResultatenDataGrid = (): ReactElement => {
  const { setFieldValue, values } = useFormikContext<HypotheekVergelijkenModalType>();
  const selectedRow = useState(0);

  const getIconTekstUitslag = (data: any): string => {
    return `Maximale hypotheek ${data.Productnaam}\r\nO.b.v. inkomen en lasten: ${bedragFormat(
      data.MaximaleHypotheekBedrag
    )}\r\nO.b.v. onderpand en lening: ${bedragFormat(
      data.MaximaleHypotheekOnderpandBedrag
    )}\r\n\r\nIndicatie minder/ meer lenen: ${bedragFormat(
      data.IndicatieMeerLenenBedrag
    )}\r\nVerplichte extra aflossing: ${bedragFormat(data.VerplichteAflossingBedrag)}`;
  };

  const getIconTekstVoorwaarde = (data: any): string => {
    return `Voorwaarden ${data.Productnaam}\r\n${data?.VoorwaardenToelichtingregels?.map((r: string) => r).join(
      "\r\n"
    )}`;
  };

  const Stap3ResultaatColumns: Column[] = [
    {
      Header: "Max. hyp.",
      accessor: "UitslagMaximaleHypotheek",
      id: "iconColumn",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          0,
          castUitslag2Icon(c.original.UitslagMaximaleHypotheek, getIconTekstUitslag(c.original)),
          c.original.UitslagMaximaleHypotheek
        ),
      width: 50,
      style: { display: "flex" }
    },
    {
      Header: "VWD",
      accessor: "UitslagVoorwaarden",
      id: "iconColumn",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          1,
          castUitslag2Icon(c.original.UitslagVoorwaarden, getIconTekstVoorwaarde(c.original)),
          c.original.UitslagVoorwaarden
        ),
      width: 50,
      style: { display: "flex" }
    },
    {
      Header: "Maatschappij",
      accessor: "Productnaam",
      id: "Maatschappij",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.Productnaam, c.original.Productnaam),
      minWidth: 120
    },
    {
      Header: "Rente",
      accessor: "WerkelijkRentePercentage",
      id: "WerkelijkRentePercentage",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          3,
          percentageFormat(c.original.WerkelijkRentePercentage, 2) + "%",
          percentageFormat(c.original.WerkelijkRentePercentage, 2) + "%"
        ),
      width: 75
    },
    {
      Header: "Bruto maandlast bij aanvang",
      accessor: "BrutoMaandlastAanvangBedrag",
      id: "BrutoMaandlastAanvangBedrag",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          4,
          bedragFormat(c.original.BrutoMaandlastAanvangBedrag, 2),
          bedragFormat(c.original.BrutoMaandlastAanvangBedrag, 2)
        )
    },
    {
      Header: "Netto maand last bij aanvang",
      accessor: "NettoMaandlastAanvangBedrag",
      id: "NettoMaandlastAanvangBedrag",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          5,
          bedragFormat(c.original.NettoMaandlastAanvangBedrag, 2),
          bedragFormat(c.original.NettoMaandlastAanvangBedrag, 2)
        )
    },
    {
      Header: "Totale netto last over looptijd",
      accessor: "NettoLastTotaalBedrag",
      id: "NettoLastTotaalBedrag",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          6,
          bedragFormat(c.original.NettoLastTotaalBedrag),
          bedragFormat(c.original.NettoLastTotaalBedrag)
        )
    },
    {
      Header: "",
      accessor: "AutomatischeRentedalingDirect",
      id: "AutomatischeRentedalingDirect",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          7,
          getRentedalingStars(c.original.AutomatischeRentedalingDirect, c.original.AutomatischeRentedalingNaRvp),
          getRentedalingStars(c.original.AutomatischeRentedalingDirect, c.original.AutomatischeRentedalingNaRvp)
        ),
      width: 25
    }
  ];

  if (values.nhg) {
    Stap3ResultaatColumns.splice(-1, 1);
  }

  /* istanbul ignore next */
  const onDataGridChange = (data?: HypotheekResultaatType[]): void => {
    if (!data || !data.length) return;
    setFieldValue("sortedResultaten", data);
  };

  return (
    <>
      <DataGrid
        masterDetail
        defaultPageSize={30}
        sortable={true}
        filterable={false}
        columns={Stap3ResultaatColumns}
        showPagination={false}
        sortConfig={{
          MaatschappijNaam: SortType.Descending
        }}
        name="resultaten"
        pageSize={values.resultaten.length}
        rowCaption="resultaten"
        getTrProps={(_: any, rowInfo: any) => {
          return {
            onClick: () => {
              setFieldValue("selectedResultaat", rowInfo.original);
            }
          };
        }}
        rowSelected={selectedRow}
        filteredCallback={onDataGridChange}
        sortedCallback={onDataGridChange}
      />
      <div className={"d-flex align-items-center justify-content-start my-3"}>
        <div className={"d-flex align-items-center mx-2"}>
          <Icon name="vink" alt="vink" />
          <span className={"ml-2"}>Toetsing van maatschappij is passend.</span>
        </div>
        <div className={"d-flex align-items-center mx-2"}>
          <Icon name="uitroepteken" multiColor={true} alt="uitroepteken" />
          <span className={"ml-2"}>Toetsing is niet passend.</span>
        </div>
        <div className={"d-flex align-items-center mx-2"}>
          <Icon name="kruis" alt="kruis" />
          <span className={"ml-2"}>Geen van de toetsingen is passend.</span>
        </div>
      </div>
    </>
  );
};
